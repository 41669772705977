h1 {
  @include media-breakpoint-down(md) {
    font-size: rfs-fluid-value(3rem);
  }
}

h2 {
  @include media-breakpoint-down(md) {
    font-size: rfs-fluid-value(2.5rem);
  }
}

#main-nav .nav-link.active {
	font-weight: bold;
}

body {
	background-image: url(/img/bgtexture_other.png);
	background-size: cover;
}

body.home {
	background-image: url(/img/bgtexture_home.png);
}

.home-special {
	 background-image:
	 	url(/img/bgtexture_blue.png),
		linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 45%, rgba(0,18,51,1) 30%, rgba(0,18,51,1) 100%);
}

.slick-dots li {
	height: 16px;
	margin: 0 4px;
	width: 16px;
	button:before {
		font-size: 14px;
	}
}

.profile-block {
	&.exit {
		-webkit-box-shadow:0px 0px 10px 2px rgba(4,102,200,0.5);
		-moz-box-shadow: 0px 0px 10px 2px rgba(4,102,200,0.5);
		box-shadow: 0px 0px 10px 2px rgba(4,102,200,0.5);
	}
	a {
		color: $white;
	}
	.exit-rocket {
		max-height: 15%;
		position: absolute;
		right: 1px;
		top: 1px;
	}
	.profile-details {
	  opacity: 0;
	  display: none;
	  max-width: 98%;
	  transition: opacity ease-in-out 2s;
	}
	.profile-cover {
		background:  linear-gradient(360deg, rgba(0, 18, 51, 0.96) 12.78%, rgba(4, 102, 200, 0.4) 121.39%);
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		transition: opacity ease-in-out 0.5s;
		opacity: 0;
	}
	.profile-name {
		animation: fadeIn;
		animation-duration: 1.5s;
	}
	.company_logo {
			animation: fadeIn;
		  animation-duration: 1s;
			max-width: 40%;
			max-height: 20%;
	}
	&:hover {
		.profile-name {
			opacity: 0;
		}
		.profile-details-position {
			top: 50% !important;
		}
		.company_logo {
			animation: flipInX;
		  animation-duration: 1.5s;
		}
		.profile-details, .profile-cover {
			display:  block;
		}
		.profile-details {
			animation: fadeInUp;
		  animation-duration: 1s;
		  opacity: 1;
		}
		.profile-cover {
			opacity: 1;
		}
	}
}

#sub-footer {
	background-color: $black;
	padding: 80px 0;
	a {
		color: $gray-700;
		text-decoration: none;
		&:hover {
			color: $white;
			img { opacity: 0.8; }
		}
	}
}

// Heading animation adapted from https://codepen.io/amritleone/pen/qERPmW
.slidingVertical{
	display: inline;
}
.slidingVertical span{
	animation: topToBottom 6s ease infinite 0s;
	-ms-animation: topToBottom 6s ease infinite 0s;
	-webkit-animation: topToBottom 6s ease infinite 0s;
	opacity: 0;
	overflow: hidden;
	position: absolute;
}
.slidingVertical span:nth-child(2){
	animation-delay: 2s;
	-ms-animation-delay: 2s;
	-webkit-animation-delay: 2s;
}
.slidingVertical span:nth-child(3){
	animation-delay: 4s;
	-ms-animation-delay: 4s;
	-webkit-animation-delay: 4s;
}
/*topToBottom Animation*/
@-moz-keyframes topToBottom{
	0% { opacity: 0; }
	5% { opacity: 0; -moz-transform: translateY(30px); }
	10% { opacity: 1; -moz-transform: translateY(0px); }
	30% { opacity: 1; -moz-transform: translateY(0px); }
	40% { opacity: 0; -moz-transform: translateY(-30px); }
	80% { opacity: 0; }
	100% { opacity: 0; }
}
@-webkit-keyframes topToBottom{
	0% { opacity: 0; }
	5% { opacity: 0; -webkit-transform: translateY(30px); }
	10% { opacity: 1; -webkit-transform: translateY(0px); }
	30% { opacity: 1; -webkit-transform: translateY(0px); }
	40% { opacity: 0; -webkit-transform: translateY(-30px); }
	80% { opacity: 0; }
	100% { opacity: 0; }
}
@-ms-keyframes topToBottom{
	0% { opacity: 0; }
	5% { opacity: 0; -ms-transform: translateY(-30px); }
	10% { opacity: 1; -ms-transform: translateY(0px); }
	30% { opacity: 1; -ms-transform: translateY(0px); }
	40% { opacity: 0; -ms-transform: translateY(30px); }
	80% { opacity: 0; }
	100% { opacity: 0; }
}

.stat-title {
	display: none;
	transform: rotateX(180deg);
	color: $primary;
}
.stat-value { color: white; }
.stat.card {
	cursor: pointer;
	background-color: $primary;
	border: 1px solid transparent;
	transition: all 300ms ease-in;
	&:hover {
		background-color: $white;
		opacity: 1;
		color: $primary;
		// border: 1px solid $gray-300;
		transform: rotateX(180deg);
		box-shadow: 0px 0px 5px 0px rgba(2, 62, 125, 0.15);
		.stat-title {
			display: block;
		}
		.stat-value { display: none;  }
	}
}

.sum-news:hover {
	box-shadow: 0px 0px 10px 0px rgba(2, 62, 125, 0.20);
}

.btn-primary:hover {
	background-color: $black;
	color: $gray-600;
}

.grayscale {
	filter: grayscale(100%);
}

.home {
	.btn-close.profile-previous, .btn-close.profile-next {
		display: none;		
	}
}

.offcanvas-body {
  animation: fadeIn 5s;
}

.offcanvas.profile-container {
	width: 880px;
	padding: 8px 12px;
	overflow: auto;
	.btn-close.profile-previous {
		background-image: url('/img/icon-arrow-left-circle.svg');
	}
	.btn-close.profile-next {
		background-image: url('/img/icon-arrow-right-circle.svg');
	}
	.profile-meat {
		// overflow-y: auto;
		// height: 300px;
	}
}
@include media-breakpoint-up(md) {
  .offcanvas.profile-container {
  	.profile-details {
  	    height: calc(100vh - 240px);
  	    overflow: auto;
  	 }
  } 
}

.logo-carousel-imgholder {
	height: 32px;
}

@include media-breakpoint-up(sm) {
	.logo-carousel-imgholder {
		height: 64px;
	}
}

.team-card {
	.company_logo {
		max-height: 40px;
	}
}

// ----- HOMEGRID -----
.homegrid {
	margin-top: 3rem;
	[class^="col"] { position: relative; }
	.overlay {
		opacity: 0;
		padding: 20px;
		position: absolute;
		top: 0;
		transition: opacity ease-in-out 0.25s;
		width: calc( 100% - 24px );
		height: 100%;
		.type,.action {
			display: inline-block;
			width: 49%;
		}
		.type {
			&:before { content: "◼ " }
		}
		.action {
			text-align: right;
			&:after { content: " →" }
		}
	}
	.feature:hover .overlay {
		background-color: rgba(0,0,0,0.4);
		color: #fff;
		cursor: pointer;
		opacity: 0.5;
	}
	img {
		border: 1px solid rgba(255,255,255,0.1);
		box-shadow: 0 0 5px 2px rgba(255,255,255, 0.1);
		&:hover {
			box-shadow: 0 0 10px 2px rgba(255,255,255, 0.4);
			cursor: pointer;
		}
	}

}

// ----- VIDEO MODAL -----
#videoModal {
	.modal-dialog {
		max-width: 640px;
		margin: 30px auto;
	}
	.modal-content {
		background-color: transparent;
		border: none;
		border-radius: 0;
	}
	.modal-body {
		position:relative;
		background-color: black;
		padding:0px;
	}
	.close {
		position:absolute;
		right:-30px;
		top: -30px;
		z-index:999;
		font-size:2rem;
		font-weight: normal;
		color:#fff;
		opacity:1;
		background-color: transparent;
		border: none;
	}

}
