$font-size-base:              1rem;
$font-family-sans-serif: 			'Poppins', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$black: 											#001233;
$primary:											#0466C8;
$body-color:                	#7D8597;
$gray-600:										#979DAC;
$gray-700:										#7D8597;

$headings-font-weight:				bold;
$headings-color:							$black;
$h1-font-size:                $font-size-base * 4.5;
$h2-font-size:                $font-size-base * 3;
$h3-font-size:                $font-size-base * 2.5;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$border-radius:               .5rem;
$border-radius-sm:            .5rem;
$border-radius-lg:            .5rem;
$border-radius-pill:          .5rem;

$input-btn-padding-x:					3rem;
$input-btn-padding-y:         .5rem;
$input-btn-padding-x-lg:			3rem;
$input-btn-padding-y:         .5rem;

$input-padding-x:             .75rem;
$input-padding-x:             1rem;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: calc($spacer / 4),
  2: calc($spacer / 2),
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2.5,
  6: $spacer * 5,
  7: $spacer * 7.5
);

$position-values: (
  0: 0,
  33: 33%,
  50: 50%,
  67: 67%,
  70: 70%,
  80: 80%,
  100: 100%
);


$btn-close-width:            36px;
// $btn-close-height:           $btn-close-width;
// $btn-close-padding-x:        .25em;
// $btn-close-padding-y:        $btn-close-padding-x;
// $btn-close-color:            $black;
$btn-close-bg:               url("/img/icon-close-circle.svg");
// $btn-close-focus-shadow:     $focus-ring-box-shadow;
$btn-close-opacity:          1.0;
$btn-close-hover-opacity:    0.65;
$btn-close-focus-opacity:    0.85;
// $btn-close-disabled-opacity: .25;
// $btn-close-white-filter:     invert(1) grayscale(100%) brightness(200%);
